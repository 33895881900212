'use client'

import { ReactNode } from 'react'

export default function Layout({ children }: { children: ReactNode }) {
    return (
        <div className="bg-gray-100 min-h-screen flex justify-center md:items-center">
            {children}
        </div>
    )
}